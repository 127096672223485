#impresionesPopupContainer
{
    background-color: rgba(0,0,0,0.34);
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
#impresionesDetails
{
    padding: 20px;
    background-color: white;
}
#impresionesDetails h2
{
    text-align: center;
}
#tableImpresiones
{
    border-spacing: 2px;
}
table#tableImpresiones td {
    position: relative;
    padding: 10px;
    text-align: center;
}
table#tableImpresiones th
{
    text-align: center;
    padding: 10px;
    padding-left: 20px;
    border-bottom: 1px solid grey;
    font-weight: 500;
}
table#tableImpresiones tr:nth-of-type(even) {
    background-color: #ebf8ff;
}