header
{
    background-color:#03a9f4;
    color: white;
    position: relative;
    z-index: 1;
}
#headerUtilContainer
{
    display: flex;
    justify-content:space-between;
    align-items:center;
    height: 40px;
}
#headerUtilContainer h3{
    margin: 0;
    font-weight: 500;
}
#headerUtilContainer:first-child
{
    margin-left: 30px;
}
#burgerButton
{
    margin-right: 30px;
}