#resultList
{
  display: inline-block;
  padding: 10px 0;
}
#resultList p
{
  background-color: white;
  margin: 0;
  padding: 10px;
  max-width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}
#resultList p:nth-of-type(even)
{
  background-color: #f0faff;
}
h4.error
{
  margin-top: 0;
}
h4.label
{
  margin-bottom: 0;
  font-weight: 500;
}
select
{
  margin-top: 5px;
}
.confirmationPopup span
{
  font-weight: normal;
}
.confirmationPopup span span
{
  font-weight: 500;
  color:#03a9f4;
}
#submitWholeForm
{
  padding: 10px;
  outline: none;
  border: 1px solid #03a9f4;
  margin-top: 10px;
  background-color: #03a9f4;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.25s ease;
}
#submitWholeForm:hover
{
  color: #03a9f4;
  background-color: transparent;
  transition: 0.25s ease;
}
#resto
{
  height: 40px;
  width: 40px;
  display: inline-block;
  cursor: pointer;
}