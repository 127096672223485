  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/font/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(assets/font/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/font/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(assets/font/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(assets/font/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(assets/font/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
body{
    padding: 0;
    margin: 0;
    background-color: #F3F3F8;
    font-family: 'Roboto', sans-serif;
    color: #111;
}
.click
{
    cursor: pointer;
}

/*fix positionnement extra 4px d'un div qui aurait uniquement une image à l'intérieur*/
svg, img
{
    vertical-align:middle;
    max-width: 100%;
    max-height: 100%;
}
.pointer
{
    cursor: pointer;
}
svg[type="submit"]
{
  cursor: pointer;
}
.flexContainer
{
    display: flex;
    min-height: calc(100vh - 40px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.error
{
  color: red;
  font-weight: normal;
}
.blue
{
  color: #03a9f4;
}
.button
{
  padding: 10px;
  text-align: center;
  border: 1px solid #03a9f4;
  color: #03a9f4;
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
}
.button:hover
{
  background-color:#03a9f4;
  color: white;
  transition:color 0.25s, background-color 0.25s;
}
.centered
{
  text-align: center;
}
#scanner 
{
  display: inline-block;
}
#scanner svg
{
  width: 40px;
  height: 40px;
}
select
{
  padding:10px
}
#logOutButton
{
  padding: 10px;
  background-color: tomato;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}