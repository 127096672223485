#resto select 
{
    padding: 10px;
}
#restoPopupContainer
{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    width: 100vw;
    height: 100vh;
    display: none;
}
#restoPopupContainer.active
{
    display: block;
}
#restoPopupContainer.standalone
{
    top: 40px;
    height: calc(100vh - 40px);
}
#restoPopup
{
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: rgba(255,255,255,0.9);
    border: 1px solid;
    width: 300px;
    height: auto;
    padding: 40px 20px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}
#closeResto
{
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
    cursor: pointer;
    width: 20px;
    height: 20px;
}
#resto
{
    cursor: pointer;
    text-align: center;
}
#resto h4
{
    margin-top: 10px;
    margin-bottom: 10px;
}