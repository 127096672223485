#scanner select 
{
    padding: 10px;
}
#scannerPopupContainer
{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.8);
    width: 100vw;
    height: 100vh;
    display: none;
}
#scannerPopupContainer.active
{
    display: block;
}
#closeScan
{
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
    cursor: pointer;
}
#scanner
{
    cursor: pointer;
    text-align: center;
}
#scanner h4
{
    margin-top: 10px;
    margin-bottom: 10px;
}