nav
{
    position: fixed;
    top: 40px;
    width: 100vw;
    height: calc(100vh - 40px);
    background-color: #F3F3F8;
    z-index: 10000;
    box-sizing: border-box;
    padding: 40px 0;
    overflow: auto;
}
#mainMenu .contentContainer
{
    position: relative;
    display: block;
    margin: 0 auto;
    width: 90%;
    box-sizing: border-box;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding-bottom: 15px;
    max-width: 600px;
    justify-content: center;
    align-content: center;
}
#mainMenu.despacho .contentContainer
{
    grid-template-columns: repeat(4, 200px);
    grid-template-rows: 70px 200px 200px;
    max-width: 800px;
}
@media (max-width:860px)
{
    #mainMenu.despacho .contentContainer {
        grid-template-columns: repeat(2, 120px);
        grid-template-rows: 70px 70px 200px 200px 200px;
        max-width: 800px;
    }
    nav
    {
        top: 0;
        position:relative;
        height: auto;
        min-height: calc(100vh - 40px);
    }
}
#mainMenu a
{
    border: 1px solid #ccc;
    display: flex;
    flex-direction:column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    color: #222;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
}
#mainMenu .almacen a
{
    height: 45vw;
    max-height: 260px;
}
#mainMenu svg
{
    max-width: 60px;
    max-height: 60px;
}