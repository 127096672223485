.confirmationPopupContainer
{
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    text-align: center;
    background-color: rgba(0,0,0,0.2);
}
.confirmationPopup
{
    background-color: rgba(0,0,0,0.7);
    background-color: #fafafa;
    padding: 10px 40px;
    max-width: 600px;
    max-height: 90vh;
    box-sizing: border-box;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 7px;
}
.confirmationPopup .confirm, .confirmationPopup .deny
{
    padding: 10px;
    cursor: pointer;
    width: 30px;
    display: inline-block;
    border-radius: 5px;
    color: white;
}
.confirmationPopup .confirm
{
    background-color: #66bb6a;
    margin-right: 10px;
}
.confirmationPopup .deny
{
    background-color: #ef5350;
}