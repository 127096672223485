.ubicacion h3
{
    margin-bottom: 5px;
}
p.calle
{
    margin-bottom: 5px;
    margin-top: 0;
}
.cantidad {
    color: #03a9f4;
}