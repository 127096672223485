#stats h2
{
    margin-bottom: 0;
}
#stats table caption
{
    font-weight: bold;
}
#stats table
{
    border: 1px solid #cccccc;
    border-radius: 10px;
    min-width: 250px;
    margin: 10px;
    border-spacing: 2px;
}
#stats th
{
    padding: 10px;
    padding-left: 20px;
    border-bottom: 1px solid grey;
}
#stats tr
{
    line-height: 35px;
}
#stats table tr:nth-of-type(even) {
    background-color: #ebf8ff;
}
#stats table tr.final
{
    background-color: white;
    font-weight: bold;
    color: #03a9f4;
    border-radius: 0 0 10px 10px;
}
#quotidien, .dailyYearContainer, #totalAnnuelMainContainer
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.dailyYearMainContainer
{
    padding-bottom: 40px;
}