#mainLoader
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50% -50%);
}
#naves
{
    display: flex;
    min-height: calc(100vh - 40px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#navesContainer
{
    display: flex;
    align-items: center;
    justify-content: center;
}
#naves .nave
{
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 10px;
    box-sizing: border-box;
    margin: 5px;
    text-align: center;
    width: 350px;
    max-width: 95%;
    position: relative;
}
#naves input.calleTitle
{
    text-align: center;
    font-size: 1.5em;
    font-weight: 600;
    border: none;
    color: #039be5;
    max-width: 275px;
}
#naves #newCalleName {
    width: 80px;
    margin-top: 15px;
}
#naves #addNave
{
    width: 350px;
    max-width: 95%;
    margin-top: 20px;
    position: relative;
    text-align: center;
}
#naves #newNaveName::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #039be5;
}
#naves #newNaveName
{
    border:1px solid #039be5
}
#naves #addNave input
{
    display: inline-block;
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    color: #039be5;
    width: calc(100% - 80px);
    font-size: 1em;
    padding: 7px;
    border-radius: 5px;
    outline: none;
    max-width: 140px;
}
#naves #addNave svg
{
    display: inline-block;
    width: 30px;
    margin-top: -4px;
    margin-left: 5px;
    cursor: pointer;
}
#naves #addNave path
{
    fill:#039be5
}
#naves input.newCalleName
{
    width: 80px;
}
#naves .nave h4
{
    color: #666;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 10px;
}
#naves .nave .delete
{
    width: 25px;
    float: right;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}
#naves .nave .delete path
{
    fill:#666;
}
#naves .callesContainer
{
    text-align: center;
}
#naves .calle
{
    display: inline-block;
    padding: 5px;
    width: 40px;
    text-align: center;
    margin: 5px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #666;
}
#naves svg[type="submit"]
{
  position: absolute;
  margin-top: 4px;
  width: 28px;
}
#naves #addNave svg[type="submit"]
{
    margin-top: 16px;
}