#incidencia
{
    text-align: center;
}
#submitIncidencia
{
    background-color: transparent;
    margin-top: 20px;
}
#submitIncidencia:hover
{
    background-color: #03a9f4;
    color: white;
}
input, select
{
    margin-top: 15px;
}