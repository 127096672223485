body
{
    background-color: #fafafa;
}
a
{
    text-decoration: none;
}
#despacho input.filter
{
    margin-top: 20px;
    margin-left: 20px;
    display: block;
}
table#stockDespacho, table#tableMovimientos {
    border-spacing: 2px;
}
table#stockDespacho, table#tableMovimientos
{
    min-width: 100%;
    margin-top: 20px;
}
#stockDespacho th, table#tableMovimientos th {
    text-align: left;
    font-weight: normal;
    padding: 10px;
    padding-left: 20px;
    border-bottom: 1px solid grey;
    font-weight: 500;
}
table#tableMovimientos th
{
    text-align: center;
}
#stockDespacho tr:nth-of-type(even), table#tableMovimientos tr:nth-of-type(even)
{
    background-color: #ebf8ff;
}
#stockDespacho td,
table#tableMovimientos td
{
    position: relative;
    padding: 10px;
}
#stockDespacho td input
{
    position: relative;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    background-color: transparent;
}
#stockDespacho span.ubicacion {
    display: block;
}
#stockDespacho .naveTitle
{
    font-weight: 500;
}
#stockDespacho .cantidad
{
    color: #03a9f4;
}
#stockDespacho .ubicaciones {
    font-size: 0.9em;
}
.stockAlmacen {
    font-size: 14px;
}
#stockDespacho .history, #stockDespacho .deleteProduct, .deleteMovimiento
{
    text-align: center;
    cursor: pointer;
}
#movimientos
{
    background-color: white;
    padding: 20px;
    width: 1000px;
    max-width: 95%;
    margin: 0 auto;
    max-height: 95%;
    box-sizing: border-box;
    overflow-y: auto;
}
#movimientos h2
{
    color: #03a9f4;
}
#movimientos select
{
    padding: 10px;
}
#movimientos input[type="datetime-local" i] {
    padding: 8.5px;
}
select:focus-visible, input:focus-visible, select:hover
{
    outline-color: #03a9f4;
}
#movimientos option
{
    padding: 10px;
}
#movimientos .button
{
    margin-top:0 ;
}